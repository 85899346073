import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {RPC, BAD_REQUEST} from 'shared/api.js';
import {setFormErrors, scrollToError} from 'shared/effects.js';
import {_} from 'shared/l10n.js';
import {STEP_NAME, TENANT_PATHS, VALIDATION_ERROR} from 'shared/constants.js';
import PriceInput from 'shared/components/PriceInput.js';
import {centsToPrice} from 'shared/utils.js';

import BottomNavigation from '../../../components/BottomNavigation.js';
import FormWrapper from '../../../components/FormWrapper.js';
import {alert} from '../../../effects.js';
import {isDevFormEnabled} from '../../../lib/utils.js';
import FormHeader from '../../../components/FormHeader.js';

export default function DepositAmountScreen() {
  const history = useHistory();
  const {contract_details, token} = history.location.state;

  const {
    control,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((fields) => {
    proceed({token, fields, history, setError});
  });

  async function saveAndGoBack() {
    const fields = getValues();
    const {previous_step} = await RPC('getNavigationState', {
      path: TENANT_PATHS.DepositAmountScreen,
      contract_details: {...contract_details, ...fields},
    });
    history.push(previous_step, {
      contract_details: {...contract_details, ...fields},
      token,
    });
  }

  useEffect(() => {
    reset({...contract_details});
  }, [contract_details, reset]);

  return (
    <FormWrapper>
      <FormHeader
        title={_('Miete')}
        subtitle={
          // TRANSLATORS: There is an invisible character in the german source string; it's a soft hyphen between "Bürgschafts" and "­urkunde"
          _(
            'Wir benötigen diese Angaben, um die Bürgschafts­urkunde für Ihren Kautionsschutz zu erstellen.',
          )
        }
      />

      <Controller
        control={control}
        name="deposit_amount_cents"
        defaultValue={
          contract_details.deposit_amount_cents
            ? centsToPrice(contract_details.deposit_amount_cents)
            : ''
        }
        render={({field: {value, onChange, name}}) => (
          <PriceInput
            value={value}
            onChange={onChange}
            label="Kaution (€)"
            error={errors[name]?.message}
            name={name}
          />
        )}
      />

      <Controller
        control={control}
        name="cold_rent_cents"
        defaultValue={
          contract_details.cold_rent_cents
            ? centsToPrice(contract_details.cold_rent_cents)
            : ''
        }
        render={({field: {value, onChange, name}}) => (
          <PriceInput
            value={value}
            onChange={onChange}
            label="Kaltmiete (€)"
            error={errors[name]?.message}
            name={name}
          />
        )}
      />
      <Controller
        control={control}
        name="warm_rent_cents"
        defaultValue={
          contract_details.warm_rent_cents
            ? centsToPrice(contract_details.warm_rent_cents)
            : ''
        }
        render={({field: {value, onChange, name}}) => (
          <PriceInput
            value={value}
            onChange={onChange}
            label="Warmmiete (€)"
            error={errors[name]?.message}
            name={name}
          />
        )}
      />

      <BottomNavigation onSubmit={onSubmit} onBack={saveAndGoBack} />
    </FormWrapper>
  );
}

async function proceed({fields, token, history, setError}) {
  let next_step, contract_details;

  try {
    ({next_step, contract_details} = await RPC('submitApplicationDraftStep', {
      step_name: STEP_NAME.deposit_amount,
      token,
      tenant_index: null,
      ...fields,
      enable_dev_form_state: isDevFormEnabled(),
    }));
  } catch (err) {
    showError(err, history, setError);
    return;
  }

  history.push(next_step, {contract_details, token});
}

function showError(err, history, setError) {
  if (err.code === BAD_REQUEST) {
    if (
      err.data?.find(
        ({path, message}) =>
          path === '/deposit_amount_cents' &&
          message === VALIDATION_ERROR.deposit_exceeds_three_times_cold_rent,
      )
    ) {
      alert({
        title: _(
          'Die Kaution darf maximal das dreifache der Kaltmiete betragen, bitte korrigieren Sie den eingetragenen Betrag.',
        ),
      });
      return;
    }

    if (
      err.data?.find(
        ({path, message}) =>
          path === '/deposit_amount_cents' &&
          message === VALIDATION_ERROR.number_too_high,
      )
    ) {
      alert({
        text: _('Wir unterstützen nur Kautionen bis 7.500 €'),
      });
      return;
    }

    if (
      err.data?.find(
        ({path, message}) =>
          path === '/deposit_amount_cents' &&
          message === VALIDATION_ERROR.number_too_low,
      )
    ) {
      alert({
        title: _(
          'Aktuell beträgt die Mindestkautionshöhe 500€. Wir arbeiten daran diese Begrenzung zu verringern.',
        ),
        text: _('Bei Rückfragen wenden Sie sich bitte an den Support.'),
      });
      return;
    }

    if (
      err.data?.find(
        ({path, message}) =>
          path === '/warm_rent_cents' &&
          message === VALIDATION_ERROR.number_too_low,
      )
    ) {
      alert({
        title: _(
          'Die Kaltmiete darf nicht höher sein als die Warmmiete, bitte korrigieren Sie den eingegebenen Betrag.',
        ),
      });
      return;
    }

    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
      scrollToError(err.data);
    } else if (err.message) {
      alert({title: err.message});
    }
  } else {
    history.push(TENANT_PATHS.GenericErrorScreen);
  }
}
