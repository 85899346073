import {useFieldArray, Controller} from 'react-hook-form';
import Button from 'shared/components/Button.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import PriceInput from 'shared/components/PriceInput';
import {_} from 'shared/l10n.js';
import IconButton from 'shared/components/IconButton.js';
import {
  PROVIDERS,
  MAX_INCOME_PROOF_NUMBER_OF_DAYS_IN_THE_PAST,
} from 'shared/constants.js';
import DateInput from 'shared/components/DateInput.js';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import {ReactComponent as AddIcon} from '../../../assets/add_circle_primary.svg';
import {ReactComponent as TrashSvg} from '../../../assets/trash.svg';
import UploadDocument from '../../../components/UploadDocument.js';
import {alert} from '../../../effects.js';
import AcceptedDocumentsModal from '../../AcceptedDocumentsModal.js';

import styles from './Incomes.module.scss';

export default function Incomes({
  control,
  errors,
  getValues,
  setValue,
  provider,
}) {
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'incomes',
  });

  return (
    <div className={styles.container}>
      {fields.map((item, index) => {
        return (
          <IncomeItem
            key={item.id}
            index={index}
            control={control}
            errors={errors}
            remove={remove}
            item={item}
            getValues={getValues}
            setValue={setValue}
            provider={provider}
          />
        );
      })}
      <Button
        onClick={() =>
          append({
            cents: '',
            proofs: [],
          })
        }
        title={_('Weiteres Einkommen hinzufügen')}
        className={styles.add_income_button}
        Icon={AddIcon}
      />
    </div>
  );
}

function IncomeItem({
  index,
  control,
  errors,
  item,
  remove,
  getValues,
  setValue,
  provider,
}) {
  return (
    <div className={styles.income_wrapper}>
      {index > 0 && (
        <>
          <div className={styles.border_top} />
          <div className={styles.header}>
            <p className={text_styles.caption_left}>
              {_('Weiteres Einkommen %1 (Nebenjob)', index)}
            </p>
            <IconButton onClick={() => remove(index)}>
              <TrashSvg />
            </IconButton>
          </div>
        </>
      )}
      <Controller
        control={control}
        name={`incomes.${index}.proofs`}
        render={({field: {onChange, name}}) => (
          <UploadDocument
            key={index}
            label={_('Foto Ihres letzten Einkommensnachweises')}
            onChange={onChange}
            name={name}
            getValues={getValues}
            setValue={setValue}
            error={errors?.incomes?.[index]?.proofs?.message}
            ExplanationModal={AcceptedDocumentsModal}
          />
        )}
      />
      <Controller
        control={control}
        name={`incomes.${index}.cents`}
        defaultValue={item.cents}
        render={({field: {onChange, value, name}}) => (
          <PriceInput
            onChange={onChange}
            value={value}
            name={name}
            label={_('Nettoeinkommen des Einkommensnachweises')}
            error={errors.incomes?.[index]?.cents?.message}
          />
        )}
      />
      {provider === PROVIDERS.vvrb && (
        <Controller
          control={control}
          name={`incomes.${index}.date`}
          render={({field: {onChange, value, name}}) => (
            <DateInput
              onChange={(value) => onDateSelected({value, onChange})}
              value={value}
              name={name}
              label={_('Datum des Einkommensnachweises')}
              maxDate={new Date()}
              error={errors.incomes?.[index]?.date?.message}
            />
          )}
        />
      )}
    </div>
  );
}

function onDateSelected({value, onChange}) {
  if (
    !value ||
    differenceInCalendarDays(new Date(), new Date(value)) <=
      MAX_INCOME_PROOF_NUMBER_OF_DAYS_IN_THE_PAST
  ) {
    onChange(value);
    return;
  }

  alertOfIncomeTooOld();
  onChange(null);
}

export function alertOfIncomeTooOld() {
  alert({
    title: _('Wir akzeptieren nur aktuelle Einkommensnachweise'),
    text: _(
      'Mindestens einer der von Ihnen hochgeladenen Einkommensnachweise ist zu alt. Bitte entfernen Sie alle Nachweise, die älter als 1 Monat sind, oder ersetzen Sie diese durch aktuelle Exemplare.\n\nBei Problemen wenden Sie sich bitte an kundenservice@getmomo.de',
    ),
  });
}
